<template>
  <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
        <a @click="back" class="btn p-0 mr-md-4 text-light h5 mb-0">
          <i class="fas fa-arrow-left"></i>
        </a>
        <span
          class="
            navbar-brand
            bg-transparent
            rounded
            p-0
            text-center
            mr-0 mr-md-5
          "
        >
          <span class="h6 font-weight-bold text-truncate"
            >Upload Bukti Bayar</span
          >
        </span>
        <a href="javascript:void(0);" class="btn px-0 d-block d-md-none">
          <i class="fa fa-home text-main"></i>
        </a>
      </nav>
    </div>
    <!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">
        <div class="row mt-4 mb-5">
          <div class="col-12">
            <Notif />
            <Form
              @submit="uploadBukti"
              method="POST"
              class="mb-3 needs-validation"
            >
              <div class="row">
                <div class="col-12 col-md-6">
                  <label for="foto"
                    >Bukti Pembayaran:<span class="text-danger ml-1"
                      >*</span
                    ></label
                  >
                  <div class="custom-file mb-3">
                    <input
                      type="file"
                      ref="fileInput"
                      class="custom-file-input form-control"
                      :class="{ 'is-invalid': isPhoto }"
                      id="upload"
                      accept="image/*"
                      name="foto"
                      @input="pickFile"
                      @change="onFileChange"
                    />
                    <label
                      class="custom-file-label"
                      for="upload"
                      id="upload-label"
                      >Pilih gambar...</label
                    >
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">Isian ini wajib diisi.</div>
                  </div>
                  <div class="image-area mb-4 w-50 mx-auto p-3">
                    <img
                      v-if="state.url"
                      :src="state.url"
                      alt=""
                      class="img-fluid rounded shadow-sm mx-auto d-block"
                    />
                  </div>
                </div>
                <!-- .col-* -->
              </div>
              <!-- .form-row -->

              <hr class="no-side-margin" />

              <div class="row mt-3">
                <div class="col text-center">
                  <button type="submit" class="btn btn-main" title="Tambah">
                    <i class="fa fa-plus mr-1"></i>Tambah
                  </button>
                  <button
                    type="reset"
                    class="btn btn-light ml-1"
                    title="Reset isian"
                  >
                    <i class="fas fa-sync-alt mr-1"></i>Reset
                  </button>
                  <a href="#" class="btn btn-light ml-1" @click="batal" title="Batal"
                    ><i class="fas fa-times mr-1"></i>Batal</a
                  >
                </div>
                <!-- .col-* -->
              </div>
              <!-- .row -->
            </Form>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- main-content -->
    </div>
    <!-- .row -->
  </div>
  <!-- .container -->
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useVueSweetAlert2 } from "../../../useVueSweetAlert2.js";
import Notif from "../../../helpers/Notif.vue";
import { Form } from "vee-validate";
import axios from "axios";

export default {
  setup() {
    const $swal = useVueSweetAlert2();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      id: "",
      form: {
        photo: "",
      },
      loading: false,
      url: "",
    });
    const loadData = async () => {
      await axios.get("/api/seller/upload-bukti/" + state.id).then((res) => {
        if (res.data.success) {
          state.url = process.env.VUE_APP_API_URL + res.data.photo;
        }
      });
    };
    onMounted(() => {
      state.id = route.params.id;
      loadData();
    });
    const batal = () => {
      $swal
        .fire({
          text: "Anda yakin meninggalkan form isian ini?  Perubahan tidak akan disimpan.",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.isConfirmed) {
            router.push({ path: "/jual/transaksi/info/" + state.id });
          }
        });
    };
    const uploadBukti = () => {
      state.loading = true;
      axios
        .post("/api/seller/upload-bukti/" + state.id, state.form)
        .then((res) => {
          if (res.data.success) {
            $swal.fire({
              icon: "success",
              title: "Selamat",
              text: "Update Berhasil!",
            });
            router.push({ path: "/jual/transaksi/info/" + state.id });
          }
        });
      state.loading = false;
    };
    const back = () => {
      $swal
        .fire({
          text: "Anda yakin meninggalkan form isian ini?  Perubahan tidak akan disimpan.",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.isConfirmed) {
            window.history.back();
          }
        });
    };
    return {
      state,
      batal,
      uploadBukti,
      back,
    };
  },
  methods: {
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.state.form.photo = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.state.url = URL.createObjectURL(file);
    },
  },
  computed: {
    isPhoto() {
      return this.state.form.photo === "";
    },
  },
  components: {
    Notif,
    Form,
  },
};
</script>